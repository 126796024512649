import { Component, ChangeDetectionStrategy } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'

@Component({
  selector: 'app-not-found-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if(url.includes('/relogin')){
      <div style="height:70vh;overflow:visible" class="flex items-center justify-center">
        <div style="max-width:520px">
          <div class="mb-3">
            <p class="text-base">Logging you in...</p>
          </div>
        </div>
      </div>
    }@else{
      <div style="height:70vh;overflow:visible" class="flex items-center justify-center">
        <div style="max-width:520px">
          <img class="mr-auto ml-auto mb-5" src="../../../assets/not-found.png" alt="">
          <h3 class="text-2xl font-semibold mb-2">Oops! Page not Found</h3>
          <div class="mb-3">
            <p class="text-base">Sorry, the page you're looking for doesn't exist. Check the URL for errors or return to the homepage. Contact support if you need assistance. Thanks for your understanding</p>
          </div>
          <a href="mailto:support@haulex.com" class="py-2 px-3 bg-gray-300 text-gray-900 rounded-lg text-sm" >Write to support</a>
        </div>
      </div>
    }
  `,
  styles: [
    `
      @tailwind base;
      @tailwind components;
      @tailwind utilities;
      :host {
        text-align: center;
      }
    `
  ]
})
export class NotFoundPageComponent {
  url: string
  query: Params
  constructor(private router: Router, private activateRoute: ActivatedRoute) {
    this.url = decodeURIComponent(this.router.url)
    this.query = this.activateRoute.snapshot.queryParams

    // If we have relogin path, we should redirect to url that is in this path(just to reinit all)
    if(this.url.includes('/relogin')){
      if(this.query?.url){
        setTimeout(() => {
          this.router.navigateByUrl(this.query.url)
        },Number(this.query?.delay) || 500)
      }
    }else if (this.url !== this.router.url) {
      this.router.navigateByUrl(this.url)
    }

  }
}
