<div>
    <div class="flex head-schedules-list items-center px-0"
        [ngStyle]="{
            'border-bottom': !schedules?.length ? '1px solid #D9D9D980' : 'none !important'
        }"
    >
        <div class="schedule-block header-title driver-name-container" 
        [ngClass]="{
            'truck_list_name': filters?.subType !== 'Drivers'
        }"
        style=""
        >
            <span style="text-wrap: nowrap; padding-right: 2px;">
                @if (filters?.subType == 'Drivers') {
                    Driver Name
                } @else {
                    Truck Name
                }
            </span>
            <button
                (click)="onUpdate.emit({dir: filters?.dir == 1 ? -1 : 1, sort: 'driverName'})"
                class="filter-sort-btn"
            >
                <i class="fa-solid text-gray-500"
                [ngClass]="{
                    'fa-chevron-down': (filters?.sort == 'driverName' && filters?.dir == -1) || filters?.sort !== 'driverName',
                    'fa-chevron-up': filters?.sort == 'driverName' && filters?.dir == 1
                    }"
                ></i>
            </button>
        </div>
        <div class="schedule-block header-title status-container" style="">
            <span style="text-wrap: nowrap; padding-right: 2px;">Status</span>
            <button
                (click)="onUpdate.emit({dir: filters?.dir == 1 ? -1 : 1, sort: 'status'})"
                class="filter-sort-btn"
            >
                <i class="fa-solid text-gray-500"
                [ngClass]="{
                    'fa-chevron-down': (filters?.sort == 'status' && filters?.dir == -1) || filters?.sort !== 'status',
                    'fa-chevron-up': filters?.sort == 'status' && filters?.dir == 1
                    }"
                ></i>
            </button>
        </div>
        <div class="schedule-block header-title truck-container"
        [ngClass]="{
            'truck_list_truck': filters?.subType !== 'Drivers'
        }" 
         style=""
         >
                <span style="text-wrap: nowrap; padding-right: 2px;">
                    @if (filters?.subType == 'Drivers') {
                        Truck
                    } @else {
                        Driver
                    }
                </span>
                <button
                    (click)="onUpdate.emit({dir: filters?.dir == 1 ? -1 : 1, sort: 'unit'})"
                    class="filter-sort-btn"
                >
                    <i class="fa-solid text-gray-500"
                    [ngClass]="{
                        'fa-chevron-down': (filters?.sort == 'unit' && filters?.dir == -1) || filters?.sort !== 'unit',
                        'fa-chevron-up': filters?.sort == 'unit' && filters?.dir == 1
                        }"
                    ></i>
                </button>
        </div>
        <div class="schedule-block header-title request-date-container" style="">
            <span style="text-wrap: nowrap; padding-right: 2px;">Request Date</span>
            <button
                (click)="onUpdate.emit({dir: filters?.dir == 1 ? -1 : 1, sort: 'reqDates'})"
                class="filter-sort-btn"
            >
                <i class="fa-solid text-gray-500"
                [ngClass]="{
                    'fa-chevron-down': (filters?.sort == 'reqDates' && filters?.dir == -1) || filters?.sort !== 'reqDates',
                    'fa-chevron-up': filters?.sort == 'reqDates' && filters?.dir == 1
                    }"
                ></i>
            </button>
        </div>
        <div class="schedule-block header-title dispatcher-requested-container" style="">
            <span style="text-wrap: nowrap; padding-right: 2px;">Dispatcher Requested</span>
            <button
                (click)="onUpdate.emit({dir: filters?.dir == 1 ? -1 : 1, sort: 'dispatchName'})"
                class="filter-sort-btn"
            >
                <i class="fa-solid text-gray-500"
                [ngClass]="{
                    'fa-chevron-down': (filters?.sort == 'dispatchName' && filters?.dir == -1) || filters?.sort !== 'dispatchName',
                    'fa-chevron-up': filters?.sort == 'dispatchName' && filters?.dir == 1
                    }"
                ></i>
            </button>
        </div>
        <div class="schedule-block header-title trip-name-container" style="">
            <span style="text-wrap: nowrap; padding-right: 2px;">Trip Name</span>
            <button
                (click)="onUpdate.emit({dir: filters?.dir == 1 ? -1 : 1, sort: 'tripName'})"
                class="filter-sort-btn"
            >
                <i class="fa-solid text-gray-500"
                [ngClass]="{
                    'fa-chevron-down': (filters?.sort == 'tripName' && filters?.dir == -1) || filters?.sort !== 'tripName',
                    'fa-chevron-up': filters?.sort == 'tripName' && filters?.dir == 1
                    }"
                ></i>
            </button>
        </div>
        <div class="schedule-block header-title view-request-container" style="">
        </div>
    </div>
    <div>
        @for (schedule of schedules; track $index) {
            <div class="flex main-schedules-item items-center px-0">
                <div class="schedule-block driver-name-container" 
                    [ngClass]="{
                        'truck_list_name': filters?.subType !== 'Drivers'
                    }"
                    style=""
                >
                    @if (filters?.subType == 'Drivers') {
                        <span class="driver_name">{{schedule?._local?.driver?.name}}</span>
                    } @else {
                        <span class="driver_name">                            
                            {{schedule?.unit?.name}}
                        </span>
                    }
                </div>
                <div class="schedule-block status-container" style="">
                    <span class="status"
                        [ngClass]="{
                            'pending': schedule?.status == 'PENDING',
                            'booked': schedule?.status == 'APPROVED',
                            'declined': schedule?.status == 'DECLINED'
                        }"
                    >{{schedule?.status}}</span>
                    <p class="trip_type">{{schedule?.type == 'DAY_OFF' ? 'Day Off' : 'Trip'}}</p>
                </div>
                <div class="schedule-block truck-container"
                    [ngClass]="{
                        'truck_list_truck': filters?.subType !== 'Drivers'
                    }" 
                    style=""
                >
                    @if (schedule?.type !== 'DAY_OFF' && schedule?.unit) {

                        @if (filters?.subType == 'Drivers') {
                            <span class="unit">
                                <i class="fa-solid fa-truck text-gray-500"></i>
                                {{schedule?.unit?.name}}
                            </span>
                        } @else {
                            <span class="unit">
                                {{schedule?._local?.driver?.name}}
                            </span>
                        }
                    }
                </div>
                <div class="schedule-block request-date-container" style="">
                    <span class="date">
                        <span class="startDate">{{schedule.startDate| date: 'MM/dd/yy' : 'UTC'}}</span>
                            <div class="date_lines">
                                <div class="date_line"></div>
                                <div class="date_line"></div>
                                <div class="date_line"></div>
                                <div class="date_line"></div>
                                <div class="date_line"></div>
                            </div>
                        <span class="endDate">{{schedule.endDate| date: 'MM/dd/yy' : 'UTC'}}</span>
                    </span>
                </div>
                <div class="schedule-block dispatcher-requested-container" style="">
                    <span class="dispatch_name">{{schedule?.dispatcher?.name}}</span>
                </div>
                <div class="schedule-block trip-name-container" style="">
                    @if (schedule?.type !== 'DAY_OFF' && schedule?.tripId) {
                        <a class="trip_name" [routerLink]="['/trips', schedule?.tripId]">{{schedule?._local?.trip?.tripName}}</a>
                    }
                </div>
                <div class="schedule-block view-request-container" style="">
                    <button class="view_request" (click)="openViewRequest(schedule)">View Request</button>
                </div>
            </div>
        }
    </div>
    <div class="pb-3">
        <app-pagination
          *ngIf="schedules?.length"
          (pageChange)="onPage.emit({page: $event, limit: limit})"
          [total]="total"
          [limit]="limit"
          [options]="{white: true}"
          [currentPage]="filters?.page || 1"
        ></app-pagination>
      </div>
</div>